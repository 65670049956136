import Component from "../../components";
import useStatsViewModel from "./statsViewModel.js";
import React, { PureComponent } from "react";
import ScreenComponent from "./components";
import "./stats.css";
const StatsScreen = () => {
  const {
    data,
    subCategories,
    isLoading,
    statsOf,
    getPostStats,
    posts,
    getSubCategoryOrganized,
  } = useStatsViewModel();
  return (
    <div className="container">
      <h1> Statistics</h1>
      {isLoading ? (
        <Component.LoadingAnimation />
      ) : (
        <>
          {statsOf === "category" && (
            <Component.Column justifyContent="center" alignItems="center">
              <div className="graphContainer" onClick={getSubCategoryOrganized}>
                <h3> Categories</h3>
                <ScreenComponent.StatsChart
                  data={data}
                  height={400}
                  width={Component.deviceWidth * 0.7}
                />
              </div>
            </Component.Column>
          )}
          {statsOf === "subCategory" && (
            <>
              <h2> SubCategories</h2>
              <Component.ChildContainer
                className="cardContainer"
                alignItems="center"
                flexDirection="column"
              >
                <Component.Row justifyContent="center" flexWrap="wrap">
                  {subCategories.map((subCategoryArray, index) => {
                    return (
                      <div
                        className="graphContainer"
                        onClick={getPostStats(index)}
                      >
                        <h3> {subCategoryArray[0].category} </h3>
                        <ScreenComponent.StatsChart
                          data={subCategoryArray}
                          height={300}
                          width={Component.deviceWidth * 0.7}
                        />
                      </div>
                    );
                  })}
                </Component.Row>
              </Component.ChildContainer>
            </>
          )}
          {statsOf == "post" && (
            <>
                 <h2>Posts</h2> 
              <Component.ChildContainer
                className="cardContainer"
                alignItems="center"
                flexDirection="column"
              >
                <Component.Row justifyContent="center" flexWrap="wrap">
               
                  {posts.map((post, index) => {
             
                    return (
                       
                      <div className="graphContainer">
                        <h3>  {post.subCategory}</h3> 
                        <ScreenComponent.PostChart data={post.posts} />
                      </div>
                    );
                  })}
                </Component.Row>
              </Component.ChildContainer>
            </>
          )}
        </>
      )}
    </div>
  );
};
export default StatsScreen;
