import { useState, useEffect } from "react";
import { ChatRepository } from "../../../repository/chat.repository";
import { useAlert } from "react-alert";

const useBotScriptViewModel = () => {
  const alert = useAlert();
  const [scripts, setScripts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [targetQuestion, setTargetQuestion] = useState({});
  const chatRepository = new ChatRepository();
  const [isOpen, setIsOpen] = useState(false);

  const toggleModel = (targetQuestion_id) => {
    return () => {
      setIsOpen(!isOpen);
      if (isOpen) {
        setTargetQuestion({});
      } else {
        const targetQuestion = scripts.find((item) => item._id === targetQuestion_id);
        setTargetQuestion(targetQuestion);
      }
    };
  };

  const getScript = async () => {
    try {
      setIsLoading(true);
      const response = await chatRepository.getScript();
      setScripts(response.script);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      alert.error(err.message);
    }
  };

  const getEditData = (event) => {
    try {
      setTargetQuestion({ ...targetQuestion, [event.target.name]: event.target.value });
    } catch (err) {
      alert.error(err.message);
    }
  };

  const updateScript = async () => {
    try {
      setIsLoading(true);
      const questionId = targetQuestion._id;
      const update = targetQuestion 
      setIsOpen(false);
      const response = await chatRepository.updateScript(questionId, { update });
      setTargetQuestion({});
      getScript()
      setIsLoading(false);
      alert.success(response.message);
    } catch (err) {
      alert.error(err.message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    try {
      getScript();
    } catch (err) {
      alert.error(err.message);
    }
  }, []);

  return {
    scripts,
    isLoading,
    isOpen,
    targetQuestion,
    toggleModel,
    updateScript,
    getEditData,
  };
};

export default useBotScriptViewModel;
