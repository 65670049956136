import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom"
import { useAlert } from "react-alert"
import { JankariRepository } from '../../repository/jankari.repository';
const usePostViewModel = () => {
    const jankariRepository = new JankariRepository()
    const alert = useAlert()
    const [post, setPost] = useState({ post: "aryan" })
    const { postId } = useParams()
    const getPost = async () => {
        try {
            const response = await jankariRepository.getSinglePost(postId)
            setPost(response.post)
        } catch (err) {
            alert.error(err.message)
        }
    }

    useEffect(() => {
        getPost()
    }, [])

    return {
        post
    }
}

export default usePostViewModel;
