import { useState, useEffect } from "react";
import { JankariRepository } from "../../../repository/jankari.repository";
import { FileUploadRepository } from "../../../repository/fileUpload.repository";
import { useAlert } from "react-alert";
import { useRef } from "react";
const usePostViewModel = () => {
  const alert = useAlert();
  const fileuploadRepository = new FileUploadRepository();
  const jankariRepository = new JankariRepository();
  const [isOpen, setIsOppen] = useState(false);
  const [postData, setPostData] = useState(null);
  const [categoryList, setCategoryList] = useState([]);
  const [postImage, setPostImage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [pageNo, setPageNo] = useState(1);
  const [subCategoryId, setSubCategoryId] = useState("*");
  const [postId, setPostId] = useState(null);
  const [posts, setPosts] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [postInfo, setPostInfo] = useState(null);
  const quillRef = useRef(null);
  const [tags, setTags] = useState([]);
  const [descriptionImages, setDescriptionImages] = useState([]);

  const [isStateScheme, setIsStateScheme] = useState(false);
  const stateList = [
    "Andaman and Nicobar",
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chandigarh (UT)",
    "Chhattisgarh",
    "Dadra and Nagar Haveli (UT)",
    "Daman and Diu (UT)",
    "Delhi (NCT)",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jammu and Kashmir",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Lakshadweep (UT)",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Puducherry (UT)",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttarakhand",
    "Uttar Pradesh",
    "West Bengal",
  ];
  const [selectedStates, setSelectedStates] = useState([]);
  const toggleModel = async () => {
    try {
      setIsOppen(!isOpen);
      if (isOpen === false) {
        const response = await jankariRepository.getSubCategory("*", "*");
        setCategoryList(response.subCategories);
      } else {
        setPostData(null);
        setTags([]);
        setPostImage(null);
        setPostId(null);
        setIsUpdate(false);
      }
    } catch (error) {
      alert.error(error.message);
    }
  };
  // text editor for html

  const renderEditorImage = (imgurl) => {
    // Data URL of the image
    if (quillRef.current && imgurl) {
      const quillEditor = quillRef.current.getEditor();
      const range = quillEditor.getSelection();
      quillEditor.insertEmbed(range?.index, "image", imgurl, {
        style: "height: 200px; width: 200px;", // Added "px" units for width
      });
    }
  };

  const handleImageInsert = () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();
    input.addEventListener("change", async (event) => {
      const selectedFile = event.target.files[0];

      if (selectedFile) {
        try {
          const data = new FormData();
          data.append("image", selectedFile);
          const imgurl = await uploadImageToServer(data);
          renderEditorImage(imgurl); // Insert image in the editor
        } catch (error) {
          console.error("Error uploading image:", error);
        }
      }
    });
  };

  const uploadImageToServer = async (formData) => {
    try {
      const response = await fileuploadRepository.uploadImage(formData);

      if (response) {
        return response.imgurl;
      } else {
        throw new Error("Image upload failed");
      }
    } catch (error) {
      alert.error(error.message);
      throw error;
    }
  };

  const getPostData = (event) => {
    try {
      setPostData({ ...postData, [event.target.name]: event.target.value });
      if (event.target.name === "subCategoryId") {
        if (event.target.value === "67191702b83ee61827038a8e")
          setIsStateScheme(true);
        else {
          setIsStateScheme(false);
          setPostData((prevPostData) => {
            const updatedPostData = { ...prevPostData };
            delete updatedPostData.states;
            return updatedPostData;
          });
          setSelectedStates([]);
        }
        const oneCategory = categoryList.find(
          (category) => category._id === event.target.value
        );
        setTags(oneCategory.tags ? oneCategory.tags : []);
        if (oneCategory.tags.length === 0) {
          setPostData((prevPostData) => {
            const updatedPostData = { ...prevPostData };
            delete updatedPostData.subCategoryTag;
            return updatedPostData;
          });
        }
      }
      if (event.target.name === "states") {
        const selectedState = event.target.value;
        // If the state is already selected, remove it; otherwise, add it
        let updatedStates;
        if (selectedStates.includes(selectedState)) {
          updatedStates = selectedStates.filter(
            (state) => state !== selectedState
          );
        } else {
          updatedStates = [...selectedStates, selectedState];
        }
        setSelectedStates(updatedStates);
        setPostData((prevPostData) => ({
          ...prevPostData,
          states: updatedStates,
        }));
      }
    } catch (error) {
      alert.error(error.message);
    }
  };
  const handleFileChange = (event) => {
    try {
      const file = event.target.files[0];
      if (file) {
        if (file.size > 20 * 1024 * 1024) {
          toggleModel();
          const error = new Error("image size should be less than 1MB");
          error.statusCode = 400;
          throw error;
        }
        const data = new FormData();
        data.append("image", file);
        setPostImage(data);
      }
    } catch (error) {
      alert.error(error.message);
    }
  };
  const uploadPost = async () => {
    try {
      if (postData && postImage) {
        if (
          postData.title &&
          postData.description &&
          postData.subCategoryId &&
          postData.hindiTitle &&
          postData.hindiDescription &&
          (tags.length === 0 || postData.subCategoryTag)
        ) {
          setIsLoading(true);
          const response = await fileuploadRepository.uploadImage(postImage);
          await jankariRepository.createPost({
            ...postData,
            imgUrl: response.imgurl,
          });
          setIsLoading(false);
          toggleModel();
          setTags([]);
          setIsStateScheme(false);
          setSelectedStates([]);
          setPostData({});
          setDescriptionImages([]);
          getPosts(pageNo, subCategoryId);
          setPostImage(null);
        }
      }
    } catch (error) {
      alert.error(error.message);
      setIsLoading(false);
    }
  };

  const getPosts = async (pageNo = 1, subCategoryId = "*") => {
    try {
      setIsLoading(true);
      setPageNo(pageNo);
      const response = await jankariRepository.getPost(pageNo, subCategoryId);
      setPosts(response.posts);
      setIsLoading(false);
    } catch (error) {
      alert.error(error.message);
      setIsLoading(false);
    }
  };

  const toggleEditModel = (post_id) => {
    return () => {
      try {
        const post = posts.find((ele) => ele._id === post_id);
        setPostData(post);
        setPostId(post._id);
        post.states ? setSelectedStates(post.states) : setSelectedStates([]);
        post?.states?.length > 0
          ? setIsStateScheme(true)
          : setIsStateScheme(false);
        setIsUpdate(!isUpdate);
        toggleModel();
      } catch (error) {
        alert.error(error.message);
      }
    };
  };

  const editPOst = async () => {
    try {
      setIsLoading(true);
      if (postId) {
        if (postImage) {
          const response = await fileuploadRepository.uploadImage(postImage);
          await jankariRepository.updatePost(postId, {
            ...postData,
            imgUrl: response.imgurl,
          });
        } else {
          await jankariRepository.updatePost(postId, { ...postData });
        }
        toggleModel();
        getPosts(pageNo, subCategoryId);
        setIsLoading(false);
        setDescriptionImages([]);
        setPostId(null);
      }
    } catch (error) {
      alert.error(error.message);
      setIsLoading(false);
    }
  };

  const toggleDeleteRequest = (post_id = null) => {
    return () => {
      try {
        if (post_id) {
          setPostId(post_id);
          setDeleteAlert(true);
        } else {
          setDeleteAlert(false);
        }
      } catch (error) {
        alert.error(error.message);
      }
    };
  };
  const previousPage = async () => {
    try {
      if (pageNo > 1) await getPosts(pageNo - 1);
    } catch (error) {
      alert.error(error.message);
    }
  };

  const nextPage = async () => {
    try {
      if (posts.length > 0) await getPosts(pageNo + 1);
    } catch (error) {
      alert.error(error.message);
    }
  };

  const deletePost = async () => {
    try {
      setIsLoading(true);
      if (postId) {
        await jankariRepository.deletePost(postId);
        toggleDeleteRequest();
        setDeleteAlert(false);
        getPosts(pageNo, subCategoryId);
      }
      setIsLoading(false);
    } catch (error) {
      alert.error(error.message);
      setIsLoading(false);
    }
  };
  const togglePostInfoModel = (postId) => {
    return () => {
      try {
        const post = posts.find((ele) => ele._id === postId);
        if (post) setPostInfo(post);
        else setPostInfo(null);
      } catch (error) {
        alert.error(error.message);
      }
    };
  };
  const handleStateChange = (e) => {
    const selectedState = e.target.value;
    // If the state is already selected, remove it; otherwise, add it
    if (selectedStates.includes(selectedState)) {
      setSelectedStates(
        selectedStates.filter((state) => state !== selectedState)
      );
    } else {
      setSelectedStates([...selectedStates, selectedState]);
    }
  };
  useEffect(() => {
    try {
      getPosts(pageNo, subCategoryId);
    } catch (error) {
      alert.error(error.message);
    }
  }, [pageNo, subCategoryId]);

  return {
    toggleModel,
    isOpen,
    postData,
    getPostData,
    categoryList,
    handleFileChange,
    postImage,
    uploadPost,
    posts,
    isLoading,
    toggleEditModel,
    editPOst,
    deleteAlert,
    toggleDeleteRequest,
    deletePost,
    isUpdate,
    pageNo,
    nextPage,
    previousPage,
    postInfo,
    togglePostInfoModel,
    quillRef,
    handleImageInsert,
    //for tags
    tags,
    isStateScheme,
    stateList,
    selectedStates,
    handleStateChange,
  };
};
export default usePostViewModel;
