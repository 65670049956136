import { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { FeedRepository } from "../../../repository/feed.repository";
import { FileUploadRepository } from "../../../repository/fileUpload.repository";

const FeedViewModel = () => {
  const alert = useAlert();
  const fileUploadRepository = new FileUploadRepository();
  const feedRepository = new FeedRepository();

  // Usefull States for FeedScreen
  const [isOpen, setIsOpen] = useState(false);
  const [imgurl, setImgurl] = useState(undefined);
  const [youTubeUrl, setYouTueUrl] = useState(undefined);
  const [mediaType, setMediaType] = useState("");
  const [videoUrl, setVideoUrl] = useState(undefined);
  const [formData, setFormData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [feeds, setFeeds] = useState([]);
  const [caption, setCaption] = useState({});
  const [pageNo, setPageNo] = useState(1);
  const [isUpdate, setisUpdate] = useState(false);
  const [feedId, setFeedId] = useState(null);
  const [showDeleteAlert, setDeleteAlert] = useState(false);
  const [category, setCategory] = useState("*");
  const [categoryList, setCategoryList] = useState([]);
  const [uploadedBy, setUploadedBy] = useState("*");
  const [comments, setComments] = useState([]);
  const [approval, setApproval] = useState("approved");
  const [feedInfo, setFeedInfo] = useState(null);
  const [comment, setComment] = useState("");
  const [commentModel, setCommentModel] = useState(false);
  // function to handle the model opening or closing
  const toggleModel = () => {
    try {
      isOpen ? setIsOpen(false) : setIsOpen(true);
      setImgurl(undefined);
      setMediaType("");
      setVideoUrl("");
      setisUpdate(false);
      setCaption({});
    } catch (error) {
      alert.error(error.message);
    }
  };

  const toggleApproval = (event) => {
    try {
      setApproval(event.target.value);
      setPageNo(1);
    } catch (error) {
      alert.error(error.message);
    }
  };

  // Function to get all Categories
  const getCategories = async () => {
    try {
      let response = await feedRepository.getCategories();
      setCategoryList(response.categories);
    } catch (error) {
      alert.error(error.message);
    }
  };

  // function to change category type
  const changeCategory = (event) => {
    try {
      setCategory(event.target.value);
    } catch (error) {
      alert.error(error.message);
    }
  };

  // function to get all feeds
  const getFeeds = async (
    categoryId = "*",
    pageNo = 1,
    uploadedBy = "*",
    approval = "approved"
  ) => {
    try {
      if (pageNo >= 1) {
        setIsLoading(true);
        let response = await feedRepository.getFeeds(
          categoryId,
          pageNo,
          uploadedBy,
          approval
        );
        setPageNo(pageNo);
        setFeeds(response.feeds);
        setIsLoading(false);
      }
    } catch (error) {
      alert.error(error.message);
      setIsLoading(false);
    }
  };

  // function for getting the url of the image that has been selected and appending the image into formdata
  const handleFileChange = (event) => {
    try {
      const file = event.target.files[0];

      if (file) {
        if (file.size > 50 * 1024 * 1024) {
          toggleModel();
          const error = new Error("image size should be less than 1MB");
          error.statusCode = 400;
          throw error;
        }
        const reader = new FileReader();
        reader.onload = () => {
          if (file.type.includes("image/")) {
            setImgurl(`${reader.result}`);
            setMediaType("image");
          }
          if (file.type.includes("video/")) {
            setVideoUrl(`${reader.result}`);
            setMediaType("video");
          }
        };
        if (file.type.includes("image/")) {
          reader.readAsDataURL(file);
          const data = new FormData();
          data.append("image", file);
          setFormData(data);
        } else if (file.type.includes("video/")) {
          reader.readAsDataURL(file);
          const data = new FormData();
          data.append("video", file);

          setFormData(data);
        }
      }
    } catch (error) {
      alert.error(error.message);
    }
  };

  const handleYouTubeUrl = (url) => {
    const youtubeUrlRegex =
      /^(https?\:\/\/)?(www\.youtube\.com|youtu\.?be)\/.+$/;
    if (youtubeUrlRegex.test(url)) {
      // URL is a valid YouTube URL
      setMediaType("youtube");
      setYouTueUrl(url);
    } else {
      // URL is not a valid YouTube URL
      console.log("Not a valid YouTube URL");
      // Handle the invalid URL scenario here
    }
  };

  // Function for Uploading New Feed
  const upload = async () => {
    try {
      setIsLoading(true);
      toggleModel();
      if (videoUrl) {
        let response = await fileUploadRepository.uploadVideo(formData);

        await feedRepository.upload({
          ...caption,
          categoryId: category,
          videoUrl: response?.url,
          mediaType: "video",
        });
      }
      if (imgurl) {
        let response = await fileUploadRepository.uploadImage(formData);
        await feedRepository.upload({
          ...caption,
          categoryId: category,
          imgurl: response?.imgurl,
          mediaType: "image",
        });
      }
      if (youTubeUrl) {
        await feedRepository.upload({
          ...caption,
          categoryId: category,
          videoUrl: youTubeUrl,
          mediaType: "youtube",
        });
      }
      setIsLoading(false);
      setYouTueUrl("");
      setMediaType("");
      setImgurl("");
      setVideoUrl("");
      getFeeds(category, pageNo, uploadedBy, approval);
      alert.success("Feed updated successfully");
    } catch (error) {
      alert.error(error.message);
      setIsLoading(false);
    }
  };

  // function to get feed Caption
  const getFeedCaption = (event) => {
    try {
      setCaption({ ...caption, [event.target.name]: event.target.value });
    } catch (error) {
      alert.error(error.message);
    }
  };

  // function to Navigate Pages
  const previousPage = async () => {
    try {
      if (pageNo > 1)
        await getFeeds(category, pageNo - 1, uploadedBy, approval);
    } catch (error) {
      alert.error(error.message);
    }
  };

  const nextPage = async () => {
    try {
      if (feeds.length > 0)
        await getFeeds(category, pageNo + 1, uploadedBy, approval);
    } catch (error) {
      alert.error(error.message);
    }
  };

  // Function to open the edit Feed Model
  const openEditFeedModel = (feed_id) => {
    return () => {
      try {
        const administrators = ["Admin", "Moderator", "SuperAdmin"];
        const feed = feeds.find((ele) => ele._id === feed_id);
        if (administrators.includes(feed.user.roles)) {
          toggleModel();
          setImgurl(feed.imgurl);
          setVideoUrl(feed.videoUrl);
          setCaption({
            caption: feed.caption,
            hindiCaption: feed.hindiCaption,
          });
          setisUpdate(true);
          setFeedId(feed._id);
        } else {
          alert.error("sorry you have no permission to access this");
        }
      } catch (error) {
        alert.error(error.message);
      }
    };
  };

  // Function to save the changes on the feed
  const editFeed = async () => {
    try {
      setIsLoading(true);
      let updatedData =
        category !== "*"
          ? { ...caption, category, feedId }
          : { ...caption, feedId };
      if (formData !== null) {
        if (imgurl) {
          let response = await fileUploadRepository.uploadImage(formData);
          updatedData = { ...updatedData, imgurl: response.imgurl };
        }
        if (videoUrl) {
          let response = await fileUploadRepository.uploadVideo(formData);
          updatedData = { ...updatedData, videoUrl: response.url };
        }
      }
      const response = await feedRepository.editFeed(updatedData);
      toggleModel();
      getFeeds(category, pageNo, uploadedBy, approval);

      setFormData(null);
      alert.success(response.message);
    } catch (error) {
      alert.error(error.message);
    }
  };

  // Function To Toggle a Delete Alert Box
  const toggleDeleteRequest = (feed_id = null) => {
    return () => {
      try {
        if (showDeleteAlert) {
          setDeleteAlert(false);
        } else {
          setDeleteAlert(true);
          setFeedId(feed_id);
        }
      } catch (error) {
        alert.error(error.message);
      }
    };
  };

  // Function to Delete a Feed
  const deleteFeed = async () => {
    try {
      const response = await feedRepository.deleteFeed({ feedId });
      setDeleteAlert(false);
      setFeedId(null);
      getFeeds(category, pageNo, uploadedBy, approval);
      alert.success(response.message);
    } catch (error) {
      alert.error(error.message);
    }
  };

  const commentsOnFeed = (feed_id) => {
    return async () => {
      try {
        setIsLoading(true);
        const response = await feedRepository.getComments(feed_id);
        toggleCommentModel();
        setComments(response.comments);
        setFeedId(feed_id);

        setIsLoading(false);
      } catch (error) {
        alert.error(error.message);
        setIsLoading(false);
      }
    };
  };
  const getComment = (event) => {
    setComment(event.target.value);
  };
  const toggleCommentModel = () => {
    setCommentModel(!commentModel);
    setComment("");
  };
  const commentOnFeed = async () => {
    try {
      if (comment !== "") {
        setIsLoading(true);
        await feedRepository.comment(feedId, { comment });
        toggleCommentModel();
        getFeeds(category, pageNo, uploadedBy, approval);
        setIsLoading(false);
        setFeedId(null);
        setComment("");
        alert.success("Comment Successfull");
      }
    } catch (err) {
      alert.error(err.message);
    }
  };
  const chageUploadedBy = (event) => {
    try {
      setUploadedBy(event.target.value);
    } catch (error) {
      alert.error(error.message);
    }
  };

  const approve = (feed_id) => {
    return async () => {
      try {
        await feedRepository.approveFeed(feed_id);
        getFeeds(category, pageNo, uploadedBy, approval);
        alert.success("Approved Successfully");
      } catch (err) {
        alert.error("Something Went Wrong");
      }
    };
  };

  const toggleInfoModel = (feed_id) => {
    return () => {
      try {
        const feed = feeds.find((ele) => ele._id === feed_id);
        if (feed) setFeedInfo(feed);
        else setFeedInfo(null);
      } catch (error) {
        alert.error(error.message);
      }
    };
  };

  useEffect(() => {
    try {
      getFeeds(category, pageNo, uploadedBy, approval);
      getCategories();
    } catch (error) {
      alert.error(error.message);
    }
  }, [category, uploadedBy, approval]);

  return {
    // Usefull States for FeedScreen
    isOpen,
    imgurl,
    feeds,
    isLoading,
    caption,
    pageNo,
    isUpdate,
    showDeleteAlert,
    categoryList,
    category,
    toggleModel,
    handleFileChange,
    upload,
    getFeedCaption,
    toggleDeleteRequest,
    // Pagination Functions
    previousPage,
    nextPage,
    // Operation on Feed
    openEditFeedModel,
    editFeed,
    deleteFeed,
    commentsOnFeed,
    changeCategory,
    chageUploadedBy,
    toggleApproval,
    approval,
    approve,
    comments,
    toggleCommentModel,
    toggleInfoModel,
    feedInfo,
    commentOnFeed,
    videoUrl,
    comment,
    commentModel,
    getComment,
    youTubeUrl,
    mediaType,
    handleYouTubeUrl,
  };
};
export default FeedViewModel;
