import { AppUrl } from "../res/appUrl";
import { ApiService } from "../data/network/apiService";
export class UserRepository {
  constructor() {
    this.appUrl = new AppUrl();
    this.apiService = new ApiService();
  }
  async getUsers(role, pageNo) {
    try {
      const url = `${this.appUrl.userEndPoint}/details/${role}/${pageNo}`;
      return this.apiService.getGetApiResponse(url);
    } catch (error) {
      throw error;
    }
  }
  async getUserProfile(id) {
    try {
      const url = `${this.appUrl.userEndPoint}/profile/${id}`;
      return this.apiService.getGetApiResponse(url);
    } catch (error) {
      throw error;
    }
  }
}
