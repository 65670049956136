import { AppUrl } from "../res/appUrl";
import { ApiService } from "../data/network/apiService";
export class ChatRepository {
  constructor() {
    this.appUrl = new AppUrl();
    this.apiService = new ApiService();
  }
  async getAdminChats(pageNo = 1) {
    try {
      const url = `${this.appUrl.adminChatEndPoint}?page=${pageNo}`;
      return await this.apiService.getGetApiResponse(url);
    } catch (error) {
      throw error;
    }
  }
  async getScript() {
    try {
      const url = `${this.appUrl.chatEndPoint}/script/*`;
      return await this.apiService.getGetApiResponse(url);
    } catch (err) {
      throw err;
    }
  }
  async replyChat(payload) {
    try {
      const url = `${this.appUrl.adminChatEndPoint}/`;
      return await this.apiService.getPostApiResponse(url, payload);
    } catch (error) {
      throw error;
    }
  }
  async updateScript(questionId, payload) {
    try {
      const url = `${this.appUrl.adminChatEndPoint}/${questionId}`;
      return await this.apiService.getPatchApiResponse(url, payload);
    } catch (err) {
      throw err;
    }
  }
}
