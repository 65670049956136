import { useState, useEffect } from "react";
import { ChatRepository } from "../../repository/chat.repository";
import { useAlert } from "react-alert";
import { NotificationRepository } from "../../repository/notification.repository";
import { FileUploadRepository } from "../../repository/fileUpload.repository";

const useChatViewModel = () => {
  const chatRepository = new ChatRepository();
  const fileuploadRepository = new FileUploadRepository();
  const notificationRepository = new NotificationRepository();
  const alert = useAlert();

  const [chatMessages, setChatMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [targetQuestion, setTargetQuestion] = useState({});
  const [answer, setAnswer] = useState("");
  const [pageNo, setPageNo] = useState(1);
  const [suggestedImage, setSuggestedImage] = useState(null);

  const toggleModel = (chat = "", id = "") => {
    return () => {
      try {
        setIsOpen(!isOpen);
        setTargetQuestion(isOpen ? {} : { id, ...chat });
        setAnswer({});
        setSuggestedImage("");
      } catch (error) {
        alert.error(error.message);
      }
    };
  };
  const getAnswer = (event) => {
    try {
      if (event.target.name === "image") {
        const file = event.target.files[0];
        if (file) {
          if (file.size > 20 * 1024 * 1024) {
            toggleModel();
            const error = new Error("image size should be less than 1MB");
            error.statusCode = 400;
            throw error;
          }
          const data = new FormData();
          data.append("image", file);
          setSuggestedImage(data);
        }
      } else {
        setAnswer({ ...answer, [event.target.name]: event.target.value });
      }
    } catch (error) {
      alert.error(error.message);
    }
  };

  const getChats = async (pageNo = 1) => {
    try {
      if (pageNo >= 1) {
        setIsLoading(true);
        const response = await chatRepository.getAdminChats(pageNo);
        setChatMessages(response.adminChats);
        setPageNo(pageNo);
      }
    } catch (error) {
      alert.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const reply = async () => {
    try {
      if (answer.adminReply) {
        setIsLoading(true);
        const targetMessage = chatMessages.find(
          (message) => message._id === targetQuestion.id
        );
        const payload = {
          ...answer,
          chatHistoryId: targetQuestion._id,
          adminRepliedUrl: answer.adminRepliedUrl,
        };
        const notification = {
          relatedTo: targetQuestion._id,
          title: "Admin Reply",
          message: answer.adminReply ?? "notification",
          url: answer.adminRepliedUrl || "",
          user: targetMessage.user._id,
        };
        if (suggestedImage) {
          const response = await fileuploadRepository.uploadImage(
            suggestedImage
          );
          payload.adminRepliedImage = response.imgurl;
          notification.imgurl = response.imgurl;
        }
        setIsOpen(false);
        await chatRepository.replyChat(payload);
        await notificationRepository.createNotification(notification);
        setAnswer({});
        setTargetQuestion({});
        getChats();
        alert.success("Reply sent and saved successfully!");
      }
    } catch (error) {
      alert.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };
  // function to Navigate Pages
  const previousPage = async () => {
    try {
      if (pageNo > 1) await getChats(pageNo - 1);
    } catch (error) {
      alert.error(error.message);
    }
  };

  const nextPage = async () => {
    try {
      if (chatMessages.length > 0) await getChats(pageNo + 1);
    } catch (error) {
      alert.error(error.message);
    }
  };

  useEffect(() => {
    getChats(pageNo);
  }, []);

  return {
    chatMessages,
    isLoading,
    isOpen,
    targetQuestion,
    answer,
    getAnswer,
    pageNo,
    previousPage,
    nextPage,
    toggleModel,
    reply,
  };
};

export default useChatViewModel;
