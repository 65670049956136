import { useEffect, useState } from "react";
import { ReportRepository } from "../../repository/reports.repository";
import { useAlert } from "react-alert";
import { UserRepository } from "../../repository/userRepository";

export const useReportViewModal = () => {
  const reportRepository = new ReportRepository();
  const userRepository = new UserRepository();
  const [reports, setReports] = useState([]);
  const [selectedUserData, setSelectedUserData] = useState(null);
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const alert = useAlert();

  const getUserData = async (id) => {
    try {
      const userData = await userRepository.getUserProfile(id);
      if (userData) {
        setSelectedUserData(userData);
      }
    } catch (error) {
      alert.error(error.message);
    }
  };

  const toggleUserVisibility = async (id) => {
    try {
      const setUpdatedData = reports.map((report) => {
        if (report.reportedOn._id === id) {
          report.reportedOn.isDeleted = !report.reportedOn.isDeleted;
          return report;
        }
        return report;
      });
      setReports(setUpdatedData);
      await reportRepository.toggleUserVisibility(id);
    } catch (error) {
      alert.error(error.message);
    }
  };

  const handleProfileModal = async (id) => {
    if (id) {
      await getUserData(id);
      // setSelectedUser(id)
      // setIsProfileModalOpen((prev) =>!prev);
    } else {
      setSelectedUserData(null);
    }
    setIsProfileModalOpen((prev) => !prev);
  };

  const getReports = async () => {
    try {
      const response = await reportRepository.getReports();
      setReports(response.reports);
    } catch (error) {
      alert.error(error.message);
    }
  };

  useEffect(() => {
    getReports();
  }, []);

  return {
    reports,
    handleProfileModal,
    toggleUserVisibility,
    isProfileModalOpen,
    selectedUserData,
  };
};
