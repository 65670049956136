import { Link } from "react-router-dom"
import Component from "../../../components"
import useBotScriptViewModel from "./botScriptViewModel"
import ScreenComponent from  "./components/index.js"
import "./botScript.css"
const BotScript = () => {
    const {
        scripts,
        isLoading,
        isOpen,
        targetQuestion,
        toggleModel,
        getEditData,
        updateScript
    } = useBotScriptViewModel()
    return (
        <div className="container">
            <Component.Row justifyContent="space-between" alignItems="center" flexWrap="wrap">
                <h1> Script </h1>
                <Link to="/chat">
                    <Component.Button title="User Questions" />
                </Link>
            </Component.Row>
             <ScreenComponent.ScriptModel 
              isOpen ={isOpen}
              toggleModel={toggleModel}
              targetQuestion={targetQuestion}
                getEditData={getEditData}
                updateScript={updateScript}

/>
            <Component.Row justifyContent="center" alignItems="center" >
                {
                    isLoading ?
                        <Component.LoadingAnimation color="green" />
                        : (
                            <table className="scriptTable">
                               <thead>
                                <tr>
                                    <th colSpan="3">
                                        Script
                                    </th>
                                </tr>
                            </thead>
                                <tbody>
                                    {
                                        scripts.map((question , index)=>{
                                            return (
                                             <tr hey={question._id}>
                                                <Component.Row style={{ padding: "10px" }}  justifyContent="space-between" alignItems="center">
                                                  <spam > ( {index+1} )   {question.question_hi.substring(0 , 20)}......</spam>
                                                   <Component.EditButton title="View" onClick={toggleModel(question._id)}/>
                                                </Component.Row>
                                             </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>

                        )


                }
            </Component.Row>

        </div>
    )
}
export default BotScript