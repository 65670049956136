import React from "react";
import Component from "../../components";
import useCropViewModel from "./useCropsViewModel";
import ScreenComponents from "./component";
const CropScreen = () => {
  const {
    crops,
    isLoading,
    isOpen,
    toggleModal,
    getNewCropsData,
    handleFileChange,
    createCrops,
    newCropName,
    isAddCategoryOpen,
    toggleAddCategoryModal,
    handleNewCropCategory,
    createNewCategory: createNewCrategory,
    cropCategories,
    handleSelectedCategory: shandleSelectedCategory,
    cropClicked,
    cropCycleModal,
    toggleCropCycleModal,
    addNewKeyValue,
    keyValue,
    handleKeyValueChange,
    isUpdate,
    updateCrops,
    handleDurationChange,
    addNewKeyValuePair,
    duration,
    addDuration,
  } = useCropViewModel();
  return (
    <div className="container">
      <ScreenComponents.CropModal
        open={isOpen}
        newCropName={newCropName}
        toggleModal={toggleModal}
        handleFileChange={handleFileChange}
        getNewCropsData={getNewCropsData}
        createCrops={createCrops}
        cropCategories={cropCategories}
        handleNewCropCategory={handleNewCropCategory}
        cropCycleModal={cropCycleModal}
        toggleCropCycleModal={toggleCropCycleModal}
        handleKeyValueChange={handleKeyValueChange}
        isUpdate={isUpdate}
        updateCrops={updateCrops}
        handleDurationChange={handleDurationChange}
        addNewKeyValuePair={addNewKeyValuePair}
        duration={duration}
        addDuration={addDuration}
        cropName
      />
      <ScreenComponents.CropCategoryModal
        handleNewCropCategory={handleNewCropCategory}
        isAddCategoryOpen={isAddCategoryOpen}
        createNewCrategory={createNewCrategory}
        toggleAddCategoryModal={toggleAddCategoryModal}
      />
      <Component.Row justifyContent="space-between" alignItems="center">
        <h1> Crops</h1>
        <Component.Row>
          <select onChange={shandleSelectedCategory}>
            <option value="*"> ALl</option>
            {cropCategories.map((category) => {
              return <option value={category._id}> {category.name} </option>;
            })}
          </select>
          <Component.Spacer position={"right"} size={20} />
          <Component.Button
            title="New Crop Category"
            onClick={toggleAddCategoryModal}
          />
          <Component.Spacer position={"right"} size={20} />
          <Component.Button title="New Crop" onClick={toggleModal} />
        </Component.Row>
      </Component.Row>
      {isLoading ? (
        <Component.LoadingAnimation color={"green"} />
      ) : (
        <div className="CropContainer">
          <Component.Row flexWrap="wrap">
            {crops.length <= 0 ? (
              <Component.Row alignItems="center" justifyContent="center">
                <h1>Nothing To Show</h1>
              </Component.Row>
            ) : (
              <>
                {crops.map((crop) => {
                  return (
                    <div
                      onClick={() => cropClicked(crop)}
                      className="cropImageNameHolder"
                    >
                      <td>
                        <img src={crop?.image} />
                      </td>
                      <td> {crop?.name} </td>
                    </div>
                  );
                })}
              </>
            )}
          </Component.Row>
        </div>
      )}
    </div>
  );
};
export default CropScreen;
