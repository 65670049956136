import React from "react";
import "./notFoundStyle.css";

export default function NotFound() {
  return (
    <div className="mainContainer">
     <p>Not Found 404!</p>
    </div>
  );
}
