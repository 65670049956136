import React, { useRef, useState } from "react";
import Component from "../../../../components/index.js";
import "./index.css";
const FeedModel = ({
  isOpen,
  toggleModel,
  handleFileChange,
  imgurl,
  mediaType,
  videoUrl,
  caption,
  isLoading,
  isUpdate,
  youTubeUrl,
  upload,
  handleYouTubeUrl,
  editFeed,
  changeCategory,
  categoryList,
  getFeedCaption,
  category,
}) => {
  const fileInputRef = useRef(null);
  const videoInputRef = useRef(null);
  const [url, setUrl] = useState("");

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };
  const handleVideoButtonClick = () => {
    videoInputRef.current.click();
  };
  const handleUrl = (event) => {
    setUrl(event.target.value);
  };
  return (
    <Component.DailogBox isOpen={isOpen} toggleModel={toggleModel}>
      {isLoading ? (
        <Component.LoadingAnimation color={"green"} />
      ) : (
        <>
          {mediaType !== "image" &&
          mediaType !== "video" &&
          mediaType !== "youtube" ? (
            <>
              <Component.Row>
                <h1> Create Feed </h1>
              </Component.Row>
              <Component.Row>
                <h3> Please select the Feed Image</h3>
              </Component.Row>
              <Component.Row justifyContent="Space-Between" alignItems="center">
                <input
                  type="file"
                  onChange={handleFileChange}
                  name="feedImage"
                  ref={fileInputRef}
                  accept="image/png, image/jpeg"
                  id="feedImageInput"
                />
                <Component.Button
                  title="Browze Image"
                  onClick={handleButtonClick}
                />
              </Component.Row>
              <Component.Spacer position="top" size={20} />
              <Component.Row>
                <h3> Please select the Feed Video</h3>
              </Component.Row>
              <Component.Row justifyContent="Space-Between" alignItems="center">
                <input
                  type="file"
                  onChange={handleFileChange}
                  name="feedImage"
                  ref={videoInputRef}
                  accept="video/*, video/mpeg, video/webm video/quicktime"
                  id="feedImageInput"
                />
                <Component.Button
                  title="Browze Video"
                  onClick={handleVideoButtonClick}
                />
                <Component.Spacer position="top" size={20} />
              </Component.Row>
              <Component.Row>
                <h3> Please Enter YouTubeUrl </h3>
              </Component.Row>
              <Component.Row justifyContent="Space-Between" alignItems="center">
                <input
                  type="text"
                  onChange={handleUrl}
                  name="youtubeUrl"
                  placeholder="please enter YouTube URL"
                  // id="feedImageInput"
                />
                <Component.Button
                  title="upload Video"
                  onClick={() => {
                    handleYouTubeUrl(url);
                  }}
                />
                <Component.Spacer position="top" size={20} />
              </Component.Row>
            </>
          ) : (
            <>
              <Component.Column>
                {isUpdate ? <h1> Edit Your Feed</h1> : <h1> Your New Feed</h1>}
                <Component.Row alignItems="center">
                  <h2> Category</h2>
                  <Component.Spacer position="right" size={15} />
                  <select
                    className="selectCategory"
                    name="categoryId"
                    value={category}
                    onChange={changeCategory}
                  >
                    <option value="*">Select Category </option>
                    {categoryList.map((category) => {
                      return (
                        <option value={category._id}>
                          {" "}
                          {category.category}{" "}
                        </option>
                      );
                    })}
                  </select>
                </Component.Row>
                <Component.Row>
                  <h2>कैप्शन</h2>
                  <input
                    className="CaptionInput"
                    name="hindiCaption"
                    onChange={getFeedCaption}
                    type="text"
                    value={caption.hindiCaption}
                    placeholder="Add Caption"
                    id=""
                  />
                </Component.Row>
                <Component.Row>
                  <h2>Caption</h2>
                  <input
                    className="CaptionInput"
                    name="caption"
                    onChange={getFeedCaption}
                    type="text"
                    value={caption.caption}
                    placeholder="Add Caption"
                    id=""
                  />
                </Component.Row>
                {imgurl && (
                  <Component.Column>
                    <input
                      type="file"
                      onChange={handleFileChange}
                      name="feedImage"
                      ref={fileInputRef}
                      accept="image/png, image/jpeg"
                      id="feedImageInput"
                    />

                    <label for="feedImageInput">
                      <img
                        className="feedImage"
                        src={imgurl}
                        alt="not found please try again "
                      />
                    </label>
                  </Component.Column>
                )}
                {videoUrl && (
                  <Component.Column>
                    <label htmlFor="feedVideoInput">
                      <video className="feedVideo" controls>
                        <source src={videoUrl} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    </label>
                  </Component.Column>
                )}
                {mediaType === "youtube" && (
                  <iframe
                    width="560"
                    height="315"
                    src={youTubeUrl}
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowfullscreen
                  ></iframe>
                )}

                <Component.Column justifyContent="space-between">
                  <Component.Spacer position="top" size={10} />
                  <Component.Spacer position="top" size={30} />
                  <Component.Row justifyContent="flex-end">
                    {isUpdate ? (
                      <Component.Button
                        onClick={editFeed}
                        title="Save Changes"
                      />
                    ) : (
                      <Component.Button onClick={upload} title=" Create Feed" />
                    )}
                  </Component.Row>
                </Component.Column>
              </Component.Column>
            </>
          )}
        </>
      )}
    </Component.DailogBox>
  );
};
const CommentModel = ({
  comments = [],
  toggleCommentModel,
  commentOnFeed,
  comment,
  getComment,
  commentModel,
}) => {
  return (
    <Component.DailogBox
      isOpen={commentModel}
      toggleModel={toggleCommentModel}
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      <civ className="commentContainer">
        <Component.Row justifyContent="center">
          <h1> Comments</h1>
        </Component.Row>
        <Component.Spacer position="top" size={15} />
        <Component.Row flexWrap="wrap">
          <Component.DailogBoxInput
            value={comment}
            onChange={getComment}
            type="text"
            placeholder="Type Comment"
          />
          <Component.Spacer position="right" size={15} />
          <Component.Button title="Comment" onClick={commentOnFeed} />
        </Component.Row>
        <Component.Spacer position="top" size={15} />
        <Component.Column
          style={{ height: "90%", overFlow: "scroll", padding: "10px" }}
        >
          {comments.map((comment) => (
            <>
              <Component.Row alignItems="center">
                <img
                  className="CommentProfileImage"
                  src={comment.user.profileImage}
                  alt="profile"
                />
                <Component.Column style={{ marignLeft: "10px" }}>
                  <span style={{ fontWeight: "bold", fontSize: "15px" }}>
                    {comment.user.name}{" "}
                  </span>
                  <span style={{ fontWeight: "light", fontSize: "12px" }}>
                    {" "}
                    {comment.comment}{" "}
                  </span>
                </Component.Column>
              </Component.Row>
              <Component.Spacer position="top" size={15} />
            </>
          ))}
        </Component.Column>
        <div className="commentInput"></div>
      </civ>
    </Component.DailogBox>
  );
};
export default {
  FeedModel,
  CommentModel,
};
