import { useState, useEffect } from "react";
import { useAlert } from "react-alert";
import { JankariRepository } from "../../../repository/jankari.repository";
import { FileUploadRepository } from "../../../repository/fileUpload.repository";
const useJankariViewModel = () => {
  const alert = useAlert();
  const fileuploadRepository = new FileUploadRepository();
  const jankariRepository = new JankariRepository();
  const [isLoading, setIsLoading] = useState(false);
  const [heading, setHeading] = useState("Category");
  const [isOpen, setIsOpen] = useState(false);
  const [categoriesList, setCategoriesList] = useState([]);
  const [newCategoryData, setNewCategoryData] = useState({});
  const [backgorundImage, setBackgorundImage] = useState(null);
  const [iconImage, setIconImage] = useState(null);
  const [categoryId, setCategoryId] = useState(null);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [parentCategoryList, setParentCategoryList] = useState([]);
  const [categoryInfo, setCategoryinfo] = useState(null);

  //state to add new tags
  const [tags, setTags] = useState([]);

  const [isTagModalOpen, setTagModalOpen] = useState(false);
  const [newTag, setNewTag] = useState({
    name: "",
    image: null,
  });

  // Handle tag input changes
  const handleTagChange = (e) => {
    const { name, value, files } = e.target;
    setNewTag((prevTag) => ({
      ...prevTag,
      [name]: files ? files[0] : value, // For image input
    }));
  };

  // Add new tag to the list
  const addNewTag = () => {
    if (!newTag.name) {
      alert.error("Please enter a name for the tag");
      return;
    }
    if (!newTag.image) {
      alert.error("Please select an image for the tag");
      return;
    }
    setTags([...tags, newTag]);
    setNewTag({ name: "", image: null }); // Reset the input fields
    setTagModalOpen(false); // Close modal after adding
  };

  const toggleModel = (event, head = "Category") => {
    try {
      if (isOpen) {
        if (heading === "Edit") {
          setHeading(head);
        }
        setNewCategoryData({});
        setCategoryId(null);
        setBackgorundImage(null);
        setIconImage(null);
        setIsOpen(false);
        setNewTag({
          name: "",
          image: null,
        });
        setTags([]);
      } else {
        setIsOpen(true);
      }
    } catch (error) {
      alert.error(error.message);
    }
  };

  const toggleHeading = (event) => {
    try {
      setHeading(event.target.value);
      setPageNo(1);
    } catch (error) {
      alert.error(error.message);
    }
  };

  const getNewCategoryData = (event) => {
    try {
      if (event.target.name === "backgroundImage") {
        const file = event.target.files[0];
        if (file) {
          if (file.size > 20 * 1024 * 1024) {
            toggleModel();
            const error = new Error("image size should be less than 1MB");
            error.statusCode = 400;
            throw error;
          }
          const data = new FormData();
          data.append("image", file);
          setBackgorundImage(data);
        }
      } else if (event.target.name === "iconImage") {
        const file = event.target.files[0];
        if (file) {
          if (file.size > 20 * 1024 * 1024) {
            toggleModel();
            const error = new Error("image size should be less than 1MB");
            error.statusCode = 400;
            throw error;
          }
          const data = new FormData();
          data.append("image", file);
          setIconImage(data);
        }
      } else {
        setNewCategoryData({
          ...newCategoryData,
          [event.target.name]: event.target.value,
        });
      }
    } catch (err) {
      alert.error(err.message);
    }
  };

  const getCategoryList = async (pageNo = 1) => {
    try {
      setIsLoading(true);
      setPageNo(pageNo);
      if (heading === "Category") {
        const response = await jankariRepository.getCategory(pageNo);
        setCategoriesList(response.categories);
        setParentCategoryList(response.categories);
      } else if (heading === "Sub Category") {
        const response = await jankariRepository.getSubCategory(pageNo);
        setCategoriesList(response.subCategories);
      }
      setIsLoading(false);
    } catch (err) {
      alert.error(err.message);
      setIsLoading(false);
    }
  };

  const createCategory = async () => {
    try {
      if (
        backgorundImage &&
        iconImage &&
        newCategoryData.name &&
        newCategoryData.hindiName &&
        newCategoryData.description &&
        newCategoryData.hindiDescription
      ) {
        setIsLoading(true);
        let response1 = await fileuploadRepository.uploadImage(backgorundImage);
        let response2 = await fileuploadRepository.uploadImage(iconImage);
        let updatedTags = await Promise.all(
          tags.map(async (tag) => {
            const formImage = new FormData();
            formImage.append("image", tag.image);
            const data = await fileuploadRepository.uploadImage(formImage);

            return {
              ...tag,
              image: data.imgurl,
            };
          })
        );

        const payload = {
          hindiName: newCategoryData.hindiName,
          hindiDescription: newCategoryData.hindiDescription,
          name: newCategoryData.name,
          description: newCategoryData.description,
          backgroundImage: response1.imgurl,
          icon: response2.imgurl,
          tags: updatedTags,
        };
        if (heading === "Category") {
          await jankariRepository.createCategory(payload);
        } else if (heading === "Sub Category" && newCategoryData.categoryId) {
          payload.categoryId = newCategoryData.categoryId;
          await jankariRepository.createSubCategory(payload);
        }
        toggleModel();
        setIsLoading(false);
        setNewCategoryData({});
        setBackgorundImage(null);
        setIconImage(null);
        getCategoryList();
        alert.success("Uploaded Successfully");
      } else {
        alert.error("Please provide all details");
      }
    } catch (err) {
      alert.error(err.message);
      setIsLoading(false);
    }
  };

  const toggleDeleteRequest = (Category_id = null) => {
    return () => {
      try {
        if (deleteAlert) {
          setDeleteAlert(false);
        } else {
          setDeleteAlert(true);
          setCategoryId(Category_id);
        }
      } catch (error) {
        alert.error(error.message);
      }
    };
  };

  const removeTag = async (tagId) => {
    try {
      setIsLoading(true);
      if (heading === "Sub Category") await jankariRepository.removeTag(tagId);
      setDeleteAlert(false);
      setCategoryId(null);
      setIsLoading(false);
      getCategoryList();
      alert.success("Deleted Successfully");
    } catch (err) {
      alert.error(err.message);
      setIsLoading(false);
    }
  };
  const deleteCategory = async () => {
    try {
      setIsLoading(true);
      if (heading === "Category")
        await jankariRepository.deleteCategory(categoryId);
      else if (heading === "Sub Category")
        await jankariRepository.deleteSubCategory(categoryId);
      setDeleteAlert(false);
      setCategoryId(null);
      setIsLoading(false);
      getCategoryList();
      alert.success("Deleted Successfully");
    } catch (err) {
      alert.error(err.message);
      setIsLoading(false);
    }
  };

  const openEditModel = (categoryId) => {
    return () => {
      try {
        setHeading("Edit");
        setCategoryId(categoryId);
        const category = categoriesList.find(
          (category) => category._id === categoryId
        );
        setNewCategoryData(category);

        setTags(category.tags);
        toggleModel();
      } catch (err) {
        alert.error(err.message);
        setIsLoading(false);
      }
    };
  };
  const previousPage = async () => {
    try {
      if (pageNo > 1) await getCategoryList(pageNo - 1);
    } catch (error) {
      alert.error(error.message);
    }
  };

  const nextPage = async () => {
    try {
      if (categoriesList.length > 0) await getCategoryList(pageNo + 1);
    } catch (error) {
      alert.error(error.message);
    }
  };
  const editCategory = async () => {
    try {
      setIsLoading(true);
      const payload = {
        name: newCategoryData.name,
        description: newCategoryData.description,
        hindiName: newCategoryData.hindiName,
        hindiDescription: newCategoryData.hindiDescription,
        backgroundImage: newCategoryData.backgroundImage,
        icon: newCategoryData.icon,
      };
      if (backgorundImage) {
        const response = await fileuploadRepository.uploadImage(
          backgorundImage
        );
        payload.backgroundImage = response.imgurl;
      }
      if (iconImage) {
        const response = await fileuploadRepository.uploadImage(iconImage);
        payload.icon = response.imgurl;
      }
      if (!newCategoryData.categoryId) {
        await jankariRepository.updateCategory(categoryId, payload);
        toggleModel();
      } else if (newCategoryData.categoryId) {
        payload.categoryId = newCategoryData.categoryId;
        await jankariRepository.updateSubCategory(categoryId, payload);
        toggleModel("event", "Sub Category");
      }

      setBackgorundImage(null);
      setIconImage(null);
      getCategoryList();
      setIsLoading(false);
      alert.success("Edited Successfully");
    } catch (err) {
      alert.error(err.message);
      setIsLoading(false);
    }
  };
  const toggleCategoryInfoModel = (categoryId) => {
    return () => {
      try {
        const category = categoriesList.find((ele) => ele._id === categoryId);
        if (category) setCategoryinfo(category);
        else setCategoryinfo(null);
      } catch (error) {
        alert.error(error.message);
      }
    };
  };
  useEffect(() => {
    try {
      getCategoryList();
    } catch (error) {
      alert.error(error.message);
    }
  }, [heading]);

  return {
    toggleModel,
    isOpen,
    heading,
    isLoading,
    categoriesList,
    newCategoryData,
    deleteAlert,
    parentCategoryList,
    openEditModel,
    toggleHeading,
    getNewCategoryData,
    createCategory,
    toggleDeleteRequest,
    deleteCategory,
    editCategory,
    pageNo,
    nextPage,
    previousPage,
    categoryInfo,
    toggleCategoryInfoModel,
    //tags state...
    isTagModalOpen,
    setTagModalOpen,
    tags,
    newTag,
    handleTagChange,
    addNewTag,
  };
};

export default useJankariViewModel;
