import React, { useState } from "react";
import "./index.css";
import Component from "../../../../components";
import { Height } from "@mui/icons-material";
function RequestTable({ requests, toggleReportForm }) {
  return (
    <div>
      {requests.length ? (
        <table border={1} className="requestTable">
          <tr>
            <th>Name</th>
            <th>Phone No:</th>
            <th>Feild</th>
            <th>Status</th>
          </tr>
          {requests.map((request) => {
            return (
              <tr>
                <td>{request.name}</td>
                <td>{request.phoneNumber}</td>
                <td>{request?.feild?.feildName}</td>
                <td>
                  {!request.status || request.status === "Pending" ? (
                    <Component.Button
                      onClick={() => {
                        toggleReportForm(request?.feild._id);
                      }}
                      title="Pending"
                    />
                  ) : (
                    <h3>{request.status}</h3>
                  )}
                </td>
              </tr>
            );
          })}
        </table>
      ) : (
        <h1>Nothing To Show</h1>
      )}
    </div>
  );
}
const AddRequestModal = ({
  phoneNumber,
  userName,
  isLoading,
  feilds,
  isOpen,
  toggleModel,
  handlePhoneNumber,
  handleSelectedFeild,
  addRequest,
}) => {
  return (
    <Component.DailogBox
      style={{
        Height: 300,
        width: 300,
      }}
      isOpen={isOpen}
      toggleModel={toggleModel}
    >
      <h1> Add Soil Testing Request</h1>
      <Component.Column>
        <Component.AuthInputField
          value={phoneNumber}
          onChange={handlePhoneNumber}
          placeholder="Phone Number"
        />
        {phoneNumber.length === 10 && (
          <>
            {isLoading ? (
              <Component.LoadingAnimation />
            ) : (
              <>
                <Component.AuthInputField
                  placeholder={userName ? userName : "Name"}
                />
                <select onChange={handleSelectedFeild}>
                  <option> Select Feild</option>
                  {feilds?.length && (
                    <>
                      {feilds.map((feild) => {
                        return (
                          <option value={feild._id}>{feild.feildName}</option>
                        );
                      })}
                    </>
                  )}
                </select>
                <Component.Spacer position={"top"} size={50} />
                <Component.Button onClick={addRequest} title="Submit Request" />
              </>
            )}
          </>
        )}
      </Component.Column>
    </Component.DailogBox>
  );
};
const ReportFormModal = ({ selectedFeild, toggleReportForm }) => {
  return (
    <Component.DailogBox
      isOpen={selectedFeild}
      toggleModel={() => toggleReportForm("")}
    >
      <h1>Soil Report</h1>
      <Component.Row
        style={{ width: "100%", height: "100vh" }}
        alignItems="center"
        justifyContent="center"
        flexWrap="wrap"
      >
        <Component.Column alignItems="flex-start">
          <Component.Row alignItems="center" justifyContent="center">
            <h3>Field:</h3>
            <Component.InputField name="field" placeholder="Field" />
          </Component.Row>
          <Component.Spacer position={"top"} size={20} />
          <Component.Row alignItems="center" justifyContent="center">
            <h3>Area:</h3>
            <Component.InputField name="area" placeholder="Area" />
          </Component.Row>
          <Component.Spacer position={"top"} size={20} />
          <Component.Row alignItems="center" justifyContent="center">
            <h3>Carbon:</h3>
            <Component.InputField name="carbon" placeholder="Carbon" />
          </Component.Row>
          <Component.Spacer position={"top"} size={20} />
          <Component.Row alignItems="center" justifyContent="center">
            <h3>Nitrogen:</h3>
            <Component.InputField name="nitrogen" placeholder="Nitrogen" />
          </Component.Row>
          <Component.Spacer position={"top"} size={20} />
          <Component.Row alignItems="center" justifyContent="center">
            <h3>Sulfur:</h3>
            <Component.InputField name="sulfur" placeholder="Sulfur" />
          </Component.Row>
          <Component.Spacer position={"top"} size={20} />
          <Component.Row alignItems="center" justifyContent="center">
            <h3>Manganese:</h3>
            <Component.InputField name="manganese" placeholder="Manganese" />
          </Component.Row>
          <Component.Spacer position={"top"} size={20} />
          <Component.Row alignItems="center" justifyContent="center">
            <h3>Elec-conduc:</h3>
            <Component.InputField
              name="electricalConductivity"
              placeholder="Electrical Conductivity"
            />
          </Component.Row>
        </Component.Column>
        <Component.Column alignItems="flex-end" justifyContent="center">
          <Component.Row alignItems="center" justifyContent="center">
            <h3>Phosphorus:</h3>
            <Component.InputField name="phosphorus" placeholder="Phosphorus" />
          </Component.Row>
          <Component.Spacer position={"top"} size={20} />
          <Component.Row alignItems="center" justifyContent="center">
            <h3>Copper:</h3>
            <Component.InputField name="copper" placeholder="Copper" />
          </Component.Row>
          <Component.Spacer position={"top"} size={20} />
          <Component.Row alignItems="center" justifyContent="center">
            <h3>Iron:</h3>
            <Component.InputField name="iron" placeholder="Iron" />
          </Component.Row>
          <Component.Spacer position={"top"} size={20} />
          <Component.Row alignItems="center" justifyContent="center">
            <h3>Potash:</h3>
            <Component.InputField name="potash" placeholder="Potash" />
          </Component.Row>
          <Component.Spacer position={"top"} size={20} />
          <Component.Row alignItems="center" justifyContent="center">
            <h3>Boron:</h3>
            <Component.InputField name="boron" placeholder="Boron" />
          </Component.Row>
          <Component.Spacer position={"top"} size={20} />

          <Component.Row alignItems="center" justifyContent="center">
            <h3>pH:</h3>
            <Component.InputField name="pH" placeholder="pH" />
          </Component.Row>
          <Component.Spacer position={"top"} size={20} />
          <Component.Row alignItems="center" justifyContent="center">
            <h3>Zinc:</h3>
            <Component.InputField name="zinc" placeholder="Zinc" />
          </Component.Row>
        </Component.Column>
      </Component.Row>
      <Component.Row justifyContent="flex-end">
        <Component.Button title={"Submit Report"} />
      </Component.Row>
    </Component.DailogBox>
  );
};

export default {
  RequestTable,
  AddRequestModal,
  ReportFormModal,
};
