import { AppUrl } from "../res/appUrl";
import { ApiService } from "../data/network/apiService";
export class FeedRepository {
    constructor() {
        this.appUrl = new AppUrl();
    this.apiService = new ApiService();
    }
    async getFeeds(categoryId,pageNo, uploadedBy="*" , approval ="approved") {
   
        const url = `${this.appUrl.feedEndPoint}/${categoryId}/${pageNo}/${uploadedBy}/${approval}`
        try {
            return await this.apiService.getGetApiResponse(url);
        } catch (error) {
            throw error
        }
    }
    async upload(payload) {
        const url = `${this.appUrl.feedEndPoint}/`
        try {
            return await this.apiService.getPostApiResponse(url, payload);
        } catch (error) {
            throw error
        }
    }
    async editFeed(payload){
        const url = `${this.appUrl.adminFeedEndPoint}/`
        try {
            return await this.apiService.getPatchApiResponse(url, payload);
        } catch (error) {
            throw error
        }
    }
    async deleteFeed(payload){
        const url = `${this.appUrl.adminFeedEndPoint}/`
        try {
            return await this.apiService.getDeleteApiResponse(url, payload);
        } catch (error) {
            throw error
        }
    }
    async getCategories(){
        const url = `${this.appUrl.feedEndPoint}/category`
        try {
            return await this.apiService.getGetApiResponse(url);
        } catch (error) {
            throw error
        }
    }
     async createCategory(payload){
        const url = `${this.appUrl.adminFeedEndPoint}/category`
        try {
            return await this.apiService.getPostApiResponse(url, payload);
        } catch (error) {
            throw error
        }
    }
    async updateCategory(categoryId ,payload){
        const url = `${this.appUrl.adminFeedEndPoint}/category/${categoryId}`
        try {
            return await this.apiService.getPatchApiResponse(url, payload);
        } catch (error) {
            throw error
        }
    }
    async deleteCategory(categoryId){
        const url = `${this.appUrl.adminFeedEndPoint}/category/${categoryId}`
        try {
            return await this.apiService.getDeleteApiResponse(url);
        } catch (error) {
            throw error
        }
    }
    async approveFeed(feed_id){
        const url = `${this.appUrl.adminFeedEndPoint}/approve/${feed_id}`
        try {
            return await this.apiService.getPatchApiResponse(url, []);
        } catch (error) {
            throw error
        }
    }
    async getComments(feed_id){
        const url = `${this.appUrl.feedEndPoint}/getComments/${feed_id}`
        try {
            return await this.apiService.getGetApiResponse(url);
        } catch (error) {
            throw error
        }
    }
    async comment(feed_id ,payload){
        const url = `${this.appUrl.feedEndPoint}/comment/${feed_id}`
        try {
            return await this.apiService.getPatchApiResponse(url , payload);
        } catch (error) {
            throw error
        }
    }
}