import Component from "../../../components";
import QRCode from "react-qr-code";
import DownloadPDFButton from './pdfDesign';
import { BiDownload } from 'react-icons/bi';
import "./index.css"
import { useAlert } from "react-alert";
const date = new Date().toLocaleDateString();
const IdModal   = ({
    isOpen ,
    toggleModel,
     count,
     isLoading,
     ids,
     getCount,
     generate,
})=>{

    return (
        <Component.DailogBox className="dailogContainer" isOpen={isOpen} toggleModel={toggleModel} >
     {
        isLoading ? 
             <Component.LoadingAnimation color={"green"} />
        :
           <>
          {
            ids.length === 0 ? 
            <>
            <h3> 
                Enter the Number of Ids you want ot Genetate
            </h3>
              <Component.AuthInputField 
            placeholder= "Enter Number of IDs You want for Sticks"
            onChange={getCount}
            />
            <Component.Button 
            title="Generate"
            onClick={generate}
            />
            </>
            :
             <div className="modalQRcontainer"> 
             <h1 className="HeadingForQR">  QRs </h1>
             <Component.Spacer position={"top"} size={20}/>
           <table className="QRTable" border={1}>
            <tr>
              <th> Id </th>
              <th> QR</th>
              <th> Download </th>
            </tr>
           {
                ids.map(id=>{
                  return <>
                  <tr>
                  <IdRow id={id}/>
                  </tr>                
                  </>
                })
            }
           </table>
           </div>
          }
           </>
        
     }
     </Component.DailogBox>
    )

}

const IdRow = ({id}) => {
 // get the current date
 const alert = useAlert()
  const copyToClipboard = () => {
    navigator.clipboard.writeText(id);
    alert.success('ID copied to clipboard');
  };

  return (
    <>
      <td>{id}
      <Component.Spacer position={"right"} size={10} />
      <button onClick={copyToClipboard}>
      
        <BiDownload />
      </button>
      </td>
      <td>
        <QRCode size={200} style={{ height: "100px", maxWidth: "200px", width: "100%" }} value={id} viewBox={`0 0 256 256`} />
      </td>
      <td>
        <DownloadPDFButton id={id} date={date} />
      </td>  
    </>
  )
}
const RecordTable = ({
  tableData=[],
  updateStatus
})=>{
  let keys = []
  if(tableData[0])
   keys =  Object.keys(tableData[0])
  return (
    <table border={1} className="agristickDataTable">
      <tr className="tableHEader">
        <th> ID</th>
        <th> User</th>
        <td> Phone Number</td>
        <th> Feild </th>
        <th> Status</th>
        <th> QR </th>
      </tr>
      {
        tableData?.map(data=>{
    
          return (
            <tr className="agriStickDataRow" >
              <td>{ data._id } </td>           
              <td>{ data.user ? data.user.name: "NOT Addeds" }</td>
              <td>{ data.user ? data.user.phoneNumber: "NOT Addeds" }</td>
              <td>{ data.field  ? data.field.feildName : "NOT Added" }</td>
              <td>{ 
              data.status === "Created" ? 
              <select onChange={(event) => updateStatus(data._id, event.target.value)}>
              <option value="Created">Created</option>
              <option value="Loaded">Loaded</option>
            </select>
              :
              data.status
               }</td>
              <td>  <DownloadPDFButton id={data._id} date={date} />  </td>
            </tr>
          )
        })
      }
    </table>
  )
}
export default {
    IdModal,
    RecordTable
}