import { useEffect, useMemo, useState } from "react";
import { useAlert } from "react-alert";
import { AgriStickRepository as AbriStickRepository } from "../../repository/agriStick.repository";
import { FileUploadRepository } from "../../repository/fileUpload.repository";

const useCropViewModel = () => {
  const alert = useAlert();
  const fileUploadRepository = new FileUploadRepository();
  const [crops, setCrops] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isAddCategoryOpen, setIsAddCategoryOpen] = useState(false);
  const [newCropCategory, setNewCropCategory] = useState({});
  const [formData, setFormData] = useState(null);
  const [cropCategories, setCropCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("*");
  const [newCropName, setNewCropName] = useState({
    name: "",
    name_hi: "",
    category: "",
    variety: "",
  });
  const [duration, setDuration] = useState([
    {
      duration: "",
      cropCycle_json: [
        {
          key: "",
          value: "",
        },
      ],
    },
  ]);
  const [cropCycleModal, setCropCycleModal] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);

  const resetForm = () => {
    setIsUpdate(false);
    setDuration([
      {
        duration: "",
        cropCycle_json: [
          {
            key: "",
            value: "",
          },
        ],
      },
    ]);
    setFormData(null);
  };
  const toggleModal = () => {
    setIsOpen((prev) => {
      if (prev) {
        setNewCropName("");
        setNewCropCategory("");
        setIsUpdate(false);
      }
      return !prev;
    });
  };
  const toggleAddCategoryModal = () => {
    setIsAddCategoryOpen((prev) => {
      if (prev) setNewCropCategory("");
      return !prev;
    });
  };

  const getNewCropsData = (event) => {
    setNewCropName((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };
  const getCrops = async () => {
    try {
      setIsLoading(true);
      const response = await AbriStickRepository.getCrops(
        selectedCategory !== "*" ? selectedCategory : "*"
      );
      setCrops(response.crops);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      alert.error(err.message);
    }
  };
  const createCrops = async () => {
    try {
      if (
        newCropName?.name &&
        newCropName?.name_hi &&
        newCropCategory &&
        formData &&
        crops?.every((crops) => crops.name !== newCropName)
      ) {
        toggleModal();
        setIsLoading(true);
        const imgResponse = await fileUploadRepository.uploadImage(formData);
        const payload = {
          category: newCropCategory.cropCategory,
          name: newCropName?.name,
          name_hi: newCropName?.name_hi,
          image: imgResponse.imgurl,
          variety: newCropName?.variety,
        };

        await AbriStickRepository.createCrops(payload);
        alert.success("Crop Created SuccessFully");
        getCrops();

        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
      alert.error(err.message);
    } finally {
      setIsLoading(false);
      resetForm();
    }
  };
  const updateCrops = async () => {
    try {
      if (
        newCropName?.name &&
        newCropName?.name_hi &&
        crops?.every((crops) => crops.name !== newCropName)
      ) {
        toggleModal();
        setIsLoading(true);

        const imgResponse = formData
          ? await fileUploadRepository.uploadImage(formData)
          : null;
        const filteredDuration = duration.filter((item) => {
          // Only include entries with non-empty duration and cropCycle_json
          return (
            item.duration.trim() !== "" ||
            item.cropCycle_json.some((kv) => kv.key.trim() && kv.value.trim())
          );
        });
        const newDuration = filteredDuration.map((item) => ({
          ...item,
          cropCycle_json: JSON.stringify(item.cropCycle_json),
        }));

        const payload = {
          _id: newCropName?._id,
          category: newCropCategory.cropCategory,
          name: newCropName?.name,
          name_hi: newCropName?.name_hi,
          variety: newCropName?.variety,
          duration: newDuration,
          image: imgResponse?.imgurl,
        };

        await AbriStickRepository.updateCrops(payload);
      }
      alert.success("Crop Updated SuccessFully");
      getCrops();
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      alert.error(err.message);
    } finally {
      setIsLoading(false);
      resetForm();
    }
  };
  const handleFileChange = (event) => {
    try {
      const file = event.target.files[0];
      if (file) {
        if (file.size > 20 * 1024 * 1024) {
          const error = new Error("image size should be less than 1MB");
          error.statusCode = 400;
          throw error;
        }
        const data = new FormData();
        data.append("image", file);
        setFormData(data);
      }
    } catch (error) {
      alert.error(error.message);
    }
  };

  const handleNewCropCategory = (event) => {
    setNewCropCategory((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };
  const createNewCategory = async () => {
    try {
      if (newCropCategory?.name && newCropCategory?.name_hi) {
        const payload = { ...newCropCategory };
        await AbriStickRepository.createCropCategory(payload);
        toggleAddCategoryModal();
        alert.success("successfully created new crop category");
      }
    } catch (err) {
      toggleAddCategoryModal();
      alert.error(err.message);
    }
  };
  const handleSelectedCategory = (event) => {
    setSelectedCategory(event.target.value);
  };
  const getCropCategories = async () => {
    const data = await AbriStickRepository.getCropCategories();
    setCropCategories(data);
  };
  const toggleCropCycleModal = () => {
    setCropCycleModal((prev) => !prev);
  };

  const cropClicked = (crop) => {
    setIsUpdate(true);
    setNewCropName(crop);
    const formattedData =
      crop.durations.length !== 0
        ? crop.durations.map((item) => ({
            duration: item.duration,
            cropCycle_json: JSON.parse(item.cropCycle_json),
          }))
        : [
            {
              duration: "",
              cropCycle_json: [
                {
                  key: "",
                  value: "",
                },
              ],
            },
          ];

    setDuration(formattedData);
    toggleModal();
  };

  const handleDurationChange = (index, field, value) => {
    // Make a copy of the current durations array
    const updatedDuration = [...duration];
    // Update the specific field (either 'duration' or 'cropCycle_json') for the corresponding index
    updatedDuration[index][field] = value;
    // Update the state with the modified array
    setDuration(updatedDuration);
  };
  const handleKeyValueChange = (durationIndex, keyIndex, field, value) => {
    const updatedDurations = [...duration];
    updatedDurations[durationIndex].cropCycle_json[keyIndex][field] = value;
    setDuration(updatedDurations);
  };

  const addNewKeyValuePair = (durationIndex) => {
    const updatedDurations = [...duration];
    updatedDurations[durationIndex].cropCycle_json.push({ key: "", value: "" });
    setDuration(updatedDurations);
  };
  const addDuration = () => {
    setDuration([
      ...duration,
      { duration: "", cropCycle_json: [{ key: "", value: "" }] },
    ]);
  };
  useEffect(() => {
    getCropCategories();
  }, []);
  useEffect(() => {
    getCrops();
  }, [selectedCategory]);

  return {
    crops,
    isLoading,
    isOpen,
    toggleModal,
    getNewCropsData,
    handleFileChange,
    newCropName,
    createCrops,
    isAddCategoryOpen,
    toggleAddCategoryModal,
    handleNewCropCategory,
    createNewCategory: createNewCategory,
    cropCategories,
    handleSelectedCategory: handleSelectedCategory,
    cropClicked,
    cropCycleModal,
    toggleCropCycleModal,
    handleKeyValueChange,
    isUpdate,
    updateCrops,
    handleDurationChange,
    addNewKeyValuePair,
    duration,
    addDuration,
  };
};
export default useCropViewModel;
