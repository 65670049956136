import React from "react";
import { useReportViewModal } from "./report.viewModal";

import ScreenComponent from "./component";
export default function ReportScreen() {
  const {
    reports,
    handleProfileModal,
    toggleUserVisibility,
    selectedUserData,
    isProfileModalOpen,
  } = useReportViewModal();
  return (
    <div className="container">
      <h1> Reports </h1>
      <div>
        <ScreenComponent.ReportTable
          handleViewProfile={handleProfileModal}
          toggleUserVisibility={toggleUserVisibility}
          reports={reports}
        />
      </div>
      <ScreenComponent.UserProfileModal
        selectedUserData={selectedUserData}
        isOpen={isProfileModalOpen}
        onClose={handleProfileModal}
      />
    </div>
  );
}
