import { Modal } from "@mui/material";
import React from "react";
import "./index.css";
import Component from "../../../components";
function ReportScreen() {
  return (
    <Component.DailogBox

      height={Component.deviceHeight - 32}
      width={Component.deviceWidth - 32}
      isOpen={false}
      className="ReportContainer"
    >
   <h1>Soil Report</h1>
      <Component.Spacer position={"top"} size={10} />
      <table border={1} className="reportTable">
        <tr>
          <th>Element</th>
          <th>Quantity</th>
          <th>Impact</th>
        </tr>
        <tr>
          <td>AvailableCarbon</td>
          <td>0.244</td>
          <td className="less">Less</td>
        </tr>
        <tr>
          <td>Available Nitrogen</td>
          <td>130kg/hect</td>
          <td className="sufficient">Sufficient</td>
        </tr>
        <tr>
          <td>AvailableCarbon</td>
          <td>0.244</td>
          <td className="less">Less</td>
        </tr>
        <tr>
          <td>Available Nitrogen</td>
          <td>130kg/hect</td>
          <td className="sufficient">Sufficient</td>
        </tr>
        <tr>
          <td>AvailableCarbon</td>
          <td>0.244</td>
          <td className="less">Less</td>
        </tr>
        <tr>
          <td>Available Nitrogen</td>
          <td>130kg/hect</td>
          <td className="sufficient">Sufficient</td>
        </tr>

        <tr>
          <td>AvailableCarbon</td>
          <td>0.244</td>
          <td className="sufficient">Sufficient</td>
        </tr>
        <tr>
          <td>Available Nitrogen</td>
          <td>130kg/hect</td>
          <td className="sufficient">Sufficient</td>
        </tr>
      </table>
      <Component.Spacer position={"top"} size={50} />
      <h2 className="solutonHeading"> Solution / Advice</h2>
      <Component.Row justifyContent="flex-start" >
        <table border={1} className="solution1">
          <tr>
            <th>Element</th>
            <th>Quantity</th>
          </tr>
          <tr>
            <td>AvailableCarbon</td>
            <td>0.244</td>
          </tr>
          <tr>
            <td>Available Nitrogen</td>
            <td>130kg/hect</td>
          </tr>
          <tr>
            <td>AvailableCarbon</td>
            <td>0.244</td>
          </tr>
          <tr>
            <td>Available Nitrogen</td>
            <td>130kg/hect</td>
          </tr>
          <tr>
            <td>AvailableCarbon</td>
            <td>0.244</td>
          </tr>
          <tr>
            <td>Available Nitrogen</td>
            <td>130kg/hect</td>
          </tr>

          <tr>
            <td>AvailableCarbon</td>
            <td>0.244</td>
          </tr>
          <tr>
            <td>Available Nitrogen</td>
            <td>130kg/hect</td>
          </tr>
        </table>
        <table border={1} className="solution2">
          <tr>
            <th>Element</th>
            <th>Quantity</th>
            <th>Impact</th>
          </tr>
          <tr>
            <td>AvailableCarbon</td>
            <td>0.244</td>
            <td className="less">Less</td>
          </tr>
          <tr>
            <td>Available Nitrogen</td>
            <td>130kg/hect</td>
            <td className="sufficient">Sufficient</td>
          </tr>
          <tr>
            <td>AvailableCarbon</td>
            <td>0.244</td>
            <td className="less">Less</td>
          </tr>
          <tr>
            <td>Available Nitrogen</td>
            <td>130kg/hect</td>
            <td className="sufficient">Sufficient</td>
          </tr>
          <tr>
            <td>AvailableCarbon</td>
            <td>0.244</td>
            <td className="less">Less</td>
          </tr>
          <tr>
            <td>Available Nitrogen</td>
            <td>130kg/hect</td>
            <td className="sufficient">Sufficient</td>
          </tr>

          <tr>
            <td>AvailableCarbon</td>
            <td>0.244</td>
            <td className="sufficient">Sufficient</td>
          </tr>
          <tr>
            <td>Available Nitrogen</td>
            <td>130kg/hect</td>
            <td className="sufficient">Sufficient</td>
          </tr>
        </table>
      </Component.Row>
    </Component.DailogBox>
  );
}

export default ReportScreen;
