import Component from "../../../../components"
const FeedCategoryModel = ({
    isOpen,
    toggleModel,
    category,
    getCategory,
    createCategory,
    editCategory,
    isUpdate,
    categoryInHindi,
    editCategoryInHindi

}) => {
    return (
        <Component.Model open={isOpen} height={250} width={400} padding={10} >
            <h2 style={{ margin: 0 }} >  {isUpdate ? <> Edit </> : <> Add  </>} Category </h2>
            <Component.Spacer position="top" size={20} />
            <Component.Row>
                <Component.DailogBoxInput value={category} onChange={getCategory} placeholder="Category Name" type="text" />
            </Component.Row>
                       <Component.Spacer position="top" size={20} />
            <Component.Row>
                <Component.DailogBoxInput value={categoryInHindi} onChange={editCategoryInHindi} placeholder="श्रेणी का नाम" type="text" />
            </Component.Row>
            <Component.Spacer position="top" size={20} />
            <Component.Row justifyContent="flex-end">
                <Component.Button title="Cancel" color="red" onClick={toggleModel} />
                <Component.Spacer position="right" size={10} />
                <Component.Button title={isUpdate ? "Save" : "Create"} onClick={isUpdate ? editCategory : createCategory } />
            </Component.Row>
        </Component.Model>
    )
}
export default {
    FeedCategoryModel
}