import Component from "../../../../components";
import "./index.css";
import Quill from "react-quill";
import { useMemo } from "react";
import "react-quill/dist/quill.snow.css";

const PostModel = ({
  isOpen,
  toggleModel,
  postData = {},
  getPostData,
  categoryList = [],
  handleFileChange,
  uploadPost,
  isUpdate,
  editPOst,
  isLoading,
  handleImageInsert,
  quillRef,
  tags,
  isStateScheme,
  stateList,
  selectedStates,
  handleStateChange,
}) => {
  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [1, 2, false] }],
          ["bold", "italic", "underline"],
          [{ list: "ordered" }, { list: "bullet" }],
          ["image", "code-block", "link"],
        ],
        handlers: {
          image: handleImageInsert,
        },
        keyboard: {
          bindings: {
            // Handle Enter key
            handleEnter: {
              key: 13, // Enter key
              handler: function () {
                // Insert a new line (a <br> tag) instead of creating a new paragraph
                this.quill.insertText(this.quill.getSelection().index, "\n");
                this.quill.setSelection(this.quill.getSelection().index + 1);
              },
            },
            // Handle Space key
            handleSpace: {
              key: 32, // Space key
              handler: function () {
                // Insert a non-breaking space character instead of a regular space
                this.quill.insertText(
                  this.quill.getSelection().index,
                  "\u00a0"
                );
                this.quill.setSelection(this.quill.getSelection().index + 1);
              },
            },
          },
        },
      },
    }),
    []
  );

  return (
    <Component.DailogBox
      isOpen={isOpen}
      toggleModel={toggleModel}
      justifyContent="flex-start"
    >
      {isLoading ? (
        <Component.LoadingAnimation color="green" />
      ) : (
        <div className="PostContainer">
          <Component.Row
            width={Component.deviceWidth - 100}
            justifyContent="center"
          >
            {isUpdate ? <h1> Edit Post</h1> : <h1> Add Post</h1>}
          </Component.Row>
          <Component.Column>
            <Component.Row>
              <h3> Sub Category</h3>
              <Component.Spacer position="rignt" size={10} />
              <select
                onChange={getPostData}
                name="subCategoryId"
                value={postData ? postData.subCategoryId : ""}
              >
                <option value={null}> Select Sub Category</option>
                {categoryList.map((subCategory) => {
                  return (
                    <option value={subCategory._id}> {subCategory.name}</option>
                  );
                })}
              </select>
            </Component.Row>
            <Component.Spacer position="top" size={20} />

            {isStateScheme && (
              <Component.Row>
                <h3>Select States</h3>
                <Component.Spacer position="rignt" size={10} />
                <select
                  onChange={getPostData}
                  name="states"
                  value={selectedStates}
                  multiple={true}
                >
                  {stateList.map((state, index) => (
                    <option key={index} value={state}>
                      {state}
                    </option>
                  ))}
                </select>
              </Component.Row>
            )}
            {tags.length !== 0 && (
              <Component.Row>
                <h3>Select Tag</h3>
                <Component.Spacer position="rignt" size={10} />
                <select
                  onChange={getPostData}
                  name="subCategoryTag"
                  value={postData ? postData.subCategoryTag : ""}
                >
                  <option value={null}> Select Tag</option>
                  {tags.map((tag) => {
                    return <option value={tag._id}> {tag.name}</option>;
                  })}
                </select>
              </Component.Row>
            )}
            <Component.Spacer position="top" size={20} />
            <Component.Row
              justifyContent="space-between"
              alignItems="center"
              flexWrap="wrap"
            >
              <Component.Row alignItems="center">
                <h3> Title</h3>
                <Component.Spacer position="rignt" size={10} />
                <Component.DailogBoxInput
                  onChange={getPostData}
                  value={postData ? postData.title : ""}
                  name="title"
                  type="text"
                  placeholder="Enter Title"
                />
              </Component.Row>
              <Component.Row alignItems="center">
                <h3> टाइटल</h3>
                <Component.Spacer position="rignt" size={10} />
                <Component.DailogBoxInput
                  onChange={getPostData}
                  value={postData ? postData.hindiTitle : ""}
                  name="hindiTitle"
                  type="text"
                  placeholder="टाइटल"
                />
              </Component.Row>
            </Component.Row>
            <Component.Spacer position="top" size={20} />
            <Component.Row alignItems="center">
              <h3> Post Image</h3>
              <Component.Spacer position="rignt" size={10} />
              <input
                type="file"
                accept="image/png, image/jpeg"
                onChange={handleFileChange}
              />
            </Component.Row>
            <Component.Spacer position="top" size={20} />
            <h3> Description </h3>
            <textarea
              type="text"
              onChange={getPostData}
              value={postData ? postData.description : ""}
              name="description"
              placeholder="Description"
            ></textarea>
            <Component.Spacer position="top" size={20} />
            <h3> विवरण </h3>
            <Quill
              ref={quillRef}
              type="text"
              onChange={(content) => {
                getPostData({
                  target: { name: "hindiDescription", value: content },
                });
              }}
              value={postData ? postData.hindiDescription : ""}
              name="hindiDescription"
              placeholder="विवरण"
              modules={modules}
            />
            <Component.Spacer position="top" size={20} />
            <Component.Row alignItems="center">
              <h3> Youtube Link</h3>
              <Component.Spacer position="rignt" size={10} />
              <Component.DailogBoxInput
                onChange={getPostData}
                value={postData ? postData.youtubeUrl : ""}
                name="youtubeUrl"
                type="text"
                placeholder="Enter Title"
              />
            </Component.Row>
            <Component.Spacer position="top" size={20} />
            <Component.Row justifyContent="flex-end">
              <Component.Button
                title={isUpdate ? "Save Changes" : "Create Post"}
                onClick={isUpdate ? editPOst : uploadPost}
              />
            </Component.Row>
          </Component.Column>
        </div>
      )}
    </Component.DailogBox>
  );
};
export default {
  PostModel,
};
