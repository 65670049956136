import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { AgriStickRepository } from "../../repository/agriStick.repository";
const useAgriStickViewModel  = ()=>{
    const alert = useAlert();
     const [count , setCount] = useState(0)
     const [isOpen , setIsOpen] = useState(false)
     const [ids , setIds]  = useState([])
     const [isLoading , setIsLoading] = useState(false)
     const [pageNo, setPageNo] = useState(1)
     const [agriStickRecords , setAgristickRecords] = useState([])
     const toggleModel = ()=>{
        setIsOpen(!isOpen)
     }
     const getCount =  (event)=>{
        setCount(event.target.value)
     }
     const generate = async ()=>{
        if(count > 0 ){
            try{
               setIsLoading(true)
               const data = await AgriStickRepository.generateIds(count)
               setIds([...data])
              await  getRecords()
           setIsLoading(false)
            }catch(error){
                alert.error(error.message)
                setIsLoading(false)
            }
        }
     }
 const getRecords = async (pageNo=1)=>{
   try{
    setIsLoading(true)
    const data = await AgriStickRepository.getRecords(pageNo)
    setAgristickRecords([...data.data])
    setPageNo(pageNo)
    setIsLoading(false)
   }catch(err){
      alert.error(err.message)
      setIsLoading(false)
   }
 }
 const previousPage = async () => {
   try {
     if (pageNo > 1) await getRecords( pageNo - 1);
   } catch (error) {
     alert.error(error.message);
   }
 };

 const nextPage = async () => {
   try {
     if (agriStickRecords.length > 0)
       await getRecords(pageNo + 1);
   } catch (error) {
     alert.error(error.message);
   }
 };
 const updateStatus = async (id , status)=>{

     try{
        setIsLoading(true)
        const updatedRecords = agriStickRecords.map((record) => {
         if (record.id === id) {
           return { ...record, status };
         }
         return record;
       });
       setAgristickRecords(updatedRecords);
       await AgriStickRepository.updateStatus(id , status)     
       alert.success("Status Updated")    
       getRecords(pageNo)
        setIsLoading(false)
     }catch(error){
        alert.error(error.message)
        setIsLoading(false)
     }
 }
 useEffect(()=>{
     getRecords(pageNo)
 },[pageNo])

     return {
        count,
        isOpen,
        toggleModel,
        ids,
        getCount,
        generate,
         isLoading,
         agriStickRecords,
         updateStatus,
         previousPage,
         nextPage,
         pageNo
     }
}
export default useAgriStickViewModel;