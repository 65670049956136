import Component from "../../../components";
import "../crops.css";
const CropModal = ({
  toggleModal,
  open,
  getNewCropsData,
  handleFileChange,
  createCrops,
  cropCategories,
  newCropName,
  handleNewCropCategory,
  cropCycleModal,
  toggleCropCycleModal,
  handleKeyValueChange,
  isUpdate,
  updateCrops,
  handleDurationChange,
  addNewKeyValuePair,
  duration,
  addDuration,
}) => {
  return (
    <Component.Model width={500} height={300} open={open}>
      <Component.Row justifyContent="space-between" alignItems="center">
        <h2 className="cropModalHeaders"> Add Crop</h2>
        <select name="cropCategory" onChange={handleNewCropCategory}>
          <option value={""}> Select Category </option>

          {cropCategories.map((category) => {
            return <option value={category._id}> {category.name} </option>;
          })}
        </select>
      </Component.Row>
      <Component.Column>
        <h3 className="cropModalHeaders">Name</h3>
        <Component.AuthInputField
          name="name"
          value={newCropName.name}
          onChange={getNewCropsData}
          placeholder={"Crop Name"}
        />
        <h3 className="cropModalHeaders">Name in Hindi</h3>
        <Component.AuthInputField
          name="name_hi"
          value={newCropName.name_hi}
          onChange={getNewCropsData}
          placeholder={"Crop Name"}
        />
        {/* <h3 className="cropModalHeaders">Crop Variety</h3>
        <Component.AuthInputField
          name="variety"
          value={newCropName.variety}
          onChange={getNewCropsData}
          placeholder={"Crop Variety"}
        /> */}
        {/* Image */}
        {newCropName.image && (
          <img style={{ width: "100px" }} src={newCropName.image} alt="crop" />
        )}
        <h3 className="cropModalHeaders">Image</h3>
        <input
          type="file"
          onChange={handleFileChange}
          accept="image/png, image/jpeg"
          placeholder="Any Image for Suggestions"
          name="image"
        />

        <Component.Spacer position={"top"} size={30} />
        {/* Rendering key value pairs */}
        {isUpdate &&
          duration.map((duration, index) => (
            <Component.Column key={index}>
              <h3 className="cropModalHeaders">
                Duration : {duration.duration}
              </h3>
              <h3>Key-Value Pairs</h3>
              {duration.cropCycle_json.map((keyValue, keyIndex) => (
                <p key={keyIndex}>
                  {keyValue.key} :{keyValue.value}
                </p>
              ))}
              <Component.Spacer position={"top"} size={30} />
            </Component.Column>
          ))}
        {/* Durations and Key-Value Pairs */}
        {isUpdate && (
          <Component.Button
            title="Add Crop Cycle"
            onClick={toggleCropCycleModal}
          />
        )}
        {isUpdate && (
          <Component.DailogBox
            isOpen={cropCycleModal}
            toggleModel={toggleCropCycleModal}
          >
            {duration &&
              duration.map((duration, index) => (
                <Component.Column key={index}>
                  <h3 className="cropModalHeaders">Duration</h3>
                  <Component.AuthInputField
                    name="duration"
                    value={duration.duration}
                    onChange={(e) =>
                      handleDurationChange(index, "duration", e.target.value)
                    }
                    placeholder={"Enter Crop Duration"}
                  />
                  <h3>Add Key-Value Pairs</h3>
                  {duration.cropCycle_json.map((keyValue, keyIndex) => (
                    <Component.Row key={keyIndex} className="key-value-pair">
                      <Component.AuthInputField
                        name="key"
                        value={keyValue.key}
                        onChange={(e) =>
                          handleKeyValueChange(
                            index,
                            keyIndex,
                            "key",
                            e.target.value
                          )
                        }
                        placeholder={"Enter Key"}
                      />
                      <Component.AuthInputField
                        name="value"
                        value={keyValue.value}
                        onChange={(e) =>
                          handleKeyValueChange(
                            index,
                            keyIndex,
                            "value",
                            e.target.value
                          )
                        }
                        placeholder={"Enter Value"}
                      />
                    </Component.Row>
                  ))}
                  <Component.Button
                    title="Add Key-Value Pair"
                    onClick={() => addNewKeyValuePair(index)}
                  />
                  <Component.Spacer position={"top"} size={30} />
                  <Component.Button
                    title="Add Duration"
                    onClick={() => addDuration()}
                  />
                  <Component.Spacer position={"top"} size={60} />
                </Component.Column>
              ))}
            <Component.Button title="Done" onClick={toggleCropCycleModal} />
          </Component.DailogBox>
        )}
      </Component.Column>
      <Component.Spacer position={"top"} size={30} />
      <Component.Row justifyContent="flex-end" alignItems="center">
        <Component.Button title="Cancel" onClick={toggleModal} />
        <Component.Spacer position={"right"} size={20} />
        <Component.Button
          title={isUpdate ? "Update" : "Add"}
          onClick={isUpdate ? updateCrops : createCrops}
        />
      </Component.Row>
    </Component.Model>
  );
};
const CropCategoryModal = ({
  isAddCategoryOpen,
  createNewCrategory,
  toggleAddCategoryModal,
  handleNewCropCategory,
}) => {
  return (
    <Component.Model width={500} height={300} open={isAddCategoryOpen}>
      <h3>Add Category</h3>
      <Component.AuthInputField
        name="name"
        onChange={handleNewCropCategory}
        placeholder={"Crop Name"}
      />
      <h3>Add Category in hindi</h3>
      <Component.AuthInputField
        name="name_hi"
        onChange={handleNewCropCategory}
        placeholder={"Crop Name"}
      />
      <Component.Row justifyContent="flex-end" alignItems="center">
        <Component.Button title="Cancel" onClick={toggleAddCategoryModal} />
        <Component.Spacer position={"right"} size={20} />
        <Component.Button title="Add" onClick={createNewCrategory} />
      </Component.Row>
    </Component.Model>
  );
};
export default {
  CropModal,
  CropCategoryModal,
};
