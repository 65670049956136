import React, { useState, useEffect } from "react";
import { useAlert } from "react-alert";
import { JankariRepository } from "../../repository/jankari.repository";

const useStatsViewModel = () => {
  const jankariRepository = new JankariRepository();
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [posts, setPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [statsOf, setStatsOf] = useState("category");
  const alert = useAlert();

  const getData = async () => {
    try {
      setIsLoading(true);
      const response = await jankariRepository.getCategory();
      setCategories(response.categories);
      setIsLoading(false);
    } catch (err) {
      alert.error("Failed to fetch data."); 
    }
  };
 
  let data = categories.map((category) => {
    const ratio = category.count;
    return {
      name: category.hindiName,
      count: ratio,
    };
  });
  const getSubCategoryOrganized = async () => {
    try {
      setIsLoading(true);
      const response = await jankariRepository.getSubCategory();
      const groupedData = response.subCategories.reduce((acc, item) => {
        const existingGroup = acc.find(
          (group) => item.categoryId === group[0]?.categoryId
        );
        const category = categories.find(
          (category) => category._id === item.categoryId
        );
        if (existingGroup) {
          existingGroup.push({
            _id: item._id,
            category: category.hindiName,
            categoryId: item.categoryId,
            name: item.hindiName,
            count: item.count,
          });
        } else {
          acc.push([
            {
              _id: item._id,
              category: category.hindiName,
              categoryId: item.categoryId,
              name: item.hindiName,
              count: item.count,
            },
          ]);
        }
        return acc;
      }, []);
      setIsLoading(false);
      setStatsOf("subCategory");
      setSubCategories([...groupedData]);
    } catch (err) {
      alert.error(err.message)
    }
  };
  const getPostStats = (index) => async () => {
    try {
      setIsLoading(true)
      setStatsOf("post");
      const groupedData = await Promise.all(
        subCategories[index].map(async (subCategory) => {
          const response = await jankariRepository.getPost(
            " 1",
            subCategory._id
          );
          const result = {
            posts :response.posts,
            subCategory : subCategory.name
          }
       
          return result
        })
      );
      setIsLoading(false)
      setPosts([...groupedData]);
    } catch (err) {
      alert.error(err.message);
    }
  };
  useEffect(() => {
    getData();
  }, []);

  return {
    data,
    subCategories,
    getSubCategoryOrganized,
    statsOf,
    isLoading,
    posts,
    getPostStats,
  };
};

export default useStatsViewModel;
