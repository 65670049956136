import { useMemo } from "react";
import { useState } from "react";
import { AuthRepository } from "../../repository/auth.repository";
import { useAlert } from "react-alert";
const useEmployeeViewModel = () => {
  const [isOpen, setIsIpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [employeeName, setEmployeeName] = useState("");
  const [employeeEmail, setEmployeeEmail] = useState("");
  const [employeeId, setEmployeeId] = useState("");
  const [employeeRole, setEmployeeRole] = useState("");
  const authRepository = useMemo(() => new AuthRepository(), []);
  const alert = useAlert();
  const toggleModal = () => {
    setIsIpen((prev) => !prev);
  };
  const handleEmloyeeEmail = (event) => {
    setEmployeeEmail(event.target.value);
  };
  const handleEmployeeName = (event) => {
    setEmployeeName(event.target.value);
  };
  const handleEmployeeId = (event) => {
    setEmployeeId(event.target.value);
  };
  const handleEmployeeRole = (event) => {
    setEmployeeRole(event.target.value);
  };
  const _emailValidator = () => {
    const emailRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return (
      employeeEmail.trim().length > 0 && emailRegex.test(employeeEmail.trim())
    );
  };

  const validator = () =>
    _emailValidator() ||
    employeeRole.length > 0 ||
    employeeName.length > 0 ||
    employeeId.length > 0;
  const createEmployee = async () => {
    try {
      if (!validator()) return;
      console.log(validator());
      const payload = {
        companyId: employeeId,
        name: employeeName,
        email: employeeEmail,
        roles: employeeRole,
      };
      setIsLoading(true);
      await authRepository.createEmployee(payload);
      setIsLoading(false);
      toggleModal();
      alert.success(("employee Created Successflly"))
    } catch (err) {
      setIsLoading(false);
      toggleModal();
      alert.error(err.message);
    }
  };
  return {
    isOpen,
    isLoading,
    toggleModal,
    handleEmployeeId,
    handleEmployeeRole,
    handleEmployeeName,
    handleEmloyeeEmail,
    validator,
    createEmployee,
  };
};
export default useEmployeeViewModel;
