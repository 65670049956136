import { AppUrl } from "../res/appUrl";
import { ApiService } from "../data/network/apiService";
export class NotificationRepository {
  constructor() {
    this.appUrl = new AppUrl();
    this.apiService = new ApiService();
  }
  async createNotification(payload) {
    try {
       const url = `${this.appUrl.notificationsEndPoint}/`
       return await this.apiService.getPostApiResponse(url , payload)
    } catch (error) {
      throw error;
    }
  }
}